import { FunctionalComponent } from 'preact';
import { getCurrentUrl } from 'preact-router';
import { useDispatch } from 'react-redux';
import { buildURL, get as fetchGet } from 'Shared/fetch';

import { setShareModalMediaId } from '@/components/ShareModal/shareModalSlice/actions';
import { setQueryParams } from '@/hooks/route';
import { useSelector } from '@/redux/helper';
import { setShareModalMediaId as setShareModalMediaIdActionForMyAlbum } from '@/redux/slices/myAlbum/actions';

interface NextEpisodeButtonProps {
  nextVideoId: string;
}

const NextEpisodeButton: FunctionalComponent<NextEpisodeButtonProps> = ({ nextVideoId }) => {
  const dispatch = useDispatch();
  const isInsideHabitatPage = useSelector((state) => state.habitat.shareModal.open);
  const isHighlightsPage = getCurrentUrl()?.includes('/highlights');
  const isMyAlbumPage = getCurrentUrl()?.includes('/u/');

  const updateQueryParams = () => setQueryParams({ id: nextVideoId }, true);

  const handleNextEpisodeClick = async () => {
    if (isInsideHabitatPage) {
      dispatch(setShareModalMediaId(nextVideoId));
      updateQueryParams();
    } else if (isHighlightsPage) {
      updateQueryParams();
    } else if (isMyAlbumPage) {
      try {
        const { media } = await fetchGet(buildURL(`/media/${nextVideoId}`));
        if (media) {
          dispatch(setShareModalMediaIdActionForMyAlbum({ mediaId: nextVideoId, videoData: media }));
          updateQueryParams();
        }
      } catch (err) {
        console.error('Failed to fetch next video:', err);
      }
    } else {
      setQueryParams({ mediaId: nextVideoId }, true);
    }
  };

  return (
    <div className="absolute bottom-12 left-3 z-[50] flex justify-center md:left-5">
      <button
        type="button"
        onClick={handleNextEpisodeClick}
        className="h-[30px] min-w-[90px] cursor-pointer rounded-full border border-black bg-white px-1 text-[12px] text-black md:h-[40px] md:min-w-[129px] md:text-[14px]"
      >
        Next Episode
      </button>
    </div>
  );
};

export default NextEpisodeButton;
