/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type HabitatCardsMobileStates = {
  activeMobileCardsTab?: string;
  activeCardIndex: number;
  questionsCardOpen: boolean;
  schedulesCardOpen: boolean;
  albumCardOpen: boolean;
  talksCardOpen: boolean;
  albumDrawerOpen: boolean;
  talksDrawerOpen: boolean;
  aiKeeperModalOpen: boolean;
};
const initialState: HabitatCardsMobileStates = {
  activeMobileCardsTab: undefined,
  // when this value is -1 or habitat.cards.length
  // modal effect will load previous or next card bucket
  activeCardIndex: 0,
  questionsCardOpen: false,
  schedulesCardOpen: false,
  albumCardOpen: false,
  talksCardOpen: false,
  albumDrawerOpen: false,
  talksDrawerOpen: false,
  aiKeeperModalOpen: false,
};

const habitatCardsMobileSlice = createSlice({
  name: 'habitatCardsMobile',
  initialState,
  reducers: {
    openModalCards: (state, { payload }: PayloadAction<string | undefined | null>) => {
      return {
        ...state,
        activeMobileCardsTab: payload ?? undefined,
        activeCardIndex: 0,
      };
    },
    nextCard: (state, { payload }: PayloadAction<{ cardsLen: number }>) => {
      const { cardsLen } = payload;
      return state.activeCardIndex < cardsLen - 1 ? { ...state, activeCardIndex: state.activeCardIndex + 1 } : state;
    },
    prevCard: (state) => {
      return { ...state, activeCardIndex: state.activeCardIndex - 1 };
    },
    resetCardInd: (state) => {
      return { ...state, activeCardIndex: 0 };
    },
    openModalQuestions: (state, { payload }: PayloadAction<boolean>) => {
      return { ...state, questionsCardOpen: payload };
    },
    openModalSchedules: (state, { payload }: PayloadAction<boolean>) => {
      return { ...state, schedulesCardOpen: payload };
    },
    showMobileExpertTalksDrawer: (state, { payload }: PayloadAction<boolean>) => {
      return { ...state, talksDrawerOpen: payload };
    },
    showMobileAlbumDrawer: (state, { payload }: PayloadAction<boolean>) => {
      return { ...state, albumDrawerOpen: payload };
    },
    openModalAIKeeper: (state, { payload }: PayloadAction<boolean>) => {
      return { ...state, aiKeeperModalOpen: payload };
    },
    closeAllModals: (state) => {
      return initialState;
    },
  },
});

export default habitatCardsMobileSlice;
