import { infiniteQueryOptions, queryOptions } from '@tanstack/react-query';

import { Pagination } from '../types';
import { getInfiniteQueryFn, getQueryFn } from '../utils';
import { GetPlaylistsQueryParams, Playlist, PlaylistListItem } from './types';

export const getInfinitePlaylistsQueryOptions = ({ videos, pageSize = 10, ...params }: GetPlaylistsQueryParams) => {
  const videoIds = videos ? [...videos].join(',') : undefined;
  const query = { videos: videoIds, pageSize, ...params };
  return infiniteQueryOptions({
    queryKey: ['playlists', 'list', query],
    initialPageParam: 1,
    queryFn: getInfiniteQueryFn<PlaylistListItem>('/playlists', query),
    getNextPageParam: (lastPage, _, lastPageParams) =>
      !lastPage ? 1 : lastPage.total > pageSize * lastPageParams ? lastPageParams + 1 : undefined,
    select: ({ pages }) =>
      pages.length > 0
        ? ({
            total: pages[pages.length - 1].total,
            list: pages.flatMap((page) => page.list),
          } as Pagination<PlaylistListItem>)
        : undefined,
  });
};

export const getPlaylistQueryOption = (playlistId: string | undefined) =>
  queryOptions({
    queryKey: ['playlists', 'detail', playlistId],
    queryFn: getQueryFn<Playlist>(`/playlists/${playlistId}`),
    enabled: Boolean(playlistId),
  });
