import { faUpload } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { HTMLAttributes } from 'preact/compat';
import { Permission } from 'zl-shared/dist/auth';

import { cn } from '@/lib/utils';
import { VideoType } from '@/queries/media/types';
import UploadMediaButton from '@/routes/habitat/components/Album/UploadMediaButton';

import { hasPermission } from '../Authorize';
import { useHighlightType } from './hooks';

interface HighlightsListHeaderProps extends HTMLAttributes<HTMLDivElement> {
  onTypeChange: (type: VideoType) => void;
}

const HighlightsListHeader = ({ onTypeChange, className, ...rest }: HighlightsListHeaderProps) => {
  const highlightType = useHighlightType();

  return (
    <div
      className={cn('preflight flex justify-between gap-2 bg-white py-3 mobile:flex-wrap', className)}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
    >
      <div className="flex min-w-0 grow items-center">
        <div
          role="radiogroup"
          aria-label="Highlights filter"
          className="preflight preflight-fieldset flex items-center gap-2"
        >
          <a
            data-native
            role="radio"
            href="?type=clip"
            tabIndex={0}
            aria-checked={highlightType === 'clip'}
            className="preflight preflight-a rounded-lg bg-primary-green bg-opacity-15 px-2 py-1 text-xs/none text-primary-green hover:bg-opacity-100 hover:text-white aria-checked:bg-opacity-100 aria-checked:text-white"
            onClick={(e) => {
              e.preventDefault();
              onTypeChange('clip');
            }}
          >
            Latest Clips
          </a>
          <a
            data-native
            role="radio"
            href="?type=curated"
            tabIndex={-1}
            aria-checked={highlightType === 'curated'}
            className="preflight preflight-a rounded-lg bg-primary-green bg-opacity-15 px-2 py-1 text-xs/none text-primary-green hover:bg-opacity-100 hover:text-white aria-checked:bg-opacity-100 aria-checked:text-white"
            onClick={(e) => {
              e.preventDefault();
              onTypeChange('curated');
            }}
          >
            Highlights
          </a>
        </div>
      </div>
      {hasPermission(Permission.Habitat.UploadMedia) && (
        <div className="">
          <UploadMediaButton
            type="curated"
            className="!min-w-0"
            label={
              <>
                <FontAwesomeIcon icon={faUpload} />
                <span className="sr-only">Upload Curated</span>
              </>
            }
          />
        </div>
      )}
    </div>
  );
};

export default HighlightsListHeader;
