import { faHeart } from '@fortawesome/pro-light-svg-icons';
import { faHeart as faHeartSolid, faSpinner, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FunctionalComponent } from 'preact';
import { useMemo, useState } from 'preact/hooks';
import { useDispatch } from 'react-redux';
import { buildURL } from 'Shared/fetch';
import { trackMP } from 'Shared/mp';
import useFetch from 'use-http';

import { useSelector } from '@/redux/helper';
import { toggleFavoriteHabitat } from '@/redux/slices/user/actions';

interface FollowButtonProps {
  habitatId: string;
  title: string;
  isInsideTheModal?: boolean;
}

const FollowButton: FunctionalComponent<FollowButtonProps> = ({ habitatId, title, isInsideTheModal }) => {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector((state) => state.user.logged);
  const isFavorited = useSelector((state) => state.user?.favoriteHabitats?.includes(habitatId));
  const [error, setError] = useState<boolean>(false);

  const { post, del, response, loading } = useFetch(buildURL('/habitats/favorite'), {
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
  });

  const favIcon = useMemo(() => {
    if (loading) {
      return faSpinner;
    }
    if (error) {
      return faTimes;
    }
    if (isFavorited) {
      return faHeartSolid;
    }
    return faHeart;
  }, [isFavorited, loading, error]);

  const onFollow = async (evt: any) => {
    evt.stopPropagation();
    const trackingData = {
      habitatName: title,
      habitatId,
      place: 'inside-video-player',
    };

    if (isFavorited) {
      await del({ habitatId });
      trackMP('habitat-unfavourited', trackingData);
    } else {
      await post({ habitatId });
      trackMP('habitat-favourited', trackingData);
    }

    if (response.ok) {
      dispatch(toggleFavoriteHabitat(habitatId));
    } else {
      setError(true);
      setTimeout(() => {
        setError(false);
      }, 2000);
    }
  };

  const buttonStyles = useMemo(() => {
    let baseSize = '';

    if (isInsideTheModal && isFavorited) {
      baseSize = 'w-[102px] h-[31px] md:w-[144px] md:h-[42px] md:text-[14px] text-[12px]'; // following state in modal
    } else if (isInsideTheModal && !isFavorited) {
      baseSize = 'w-[80px] h-[31px] md:w-[108px] md:h-[42px] md:text-[14px] text-[12px]'; // default state in modal
    } else if (!isInsideTheModal && isFavorited) {
      baseSize = 'w-[102px] h-[31px] md:w-[160px] md:h-[53px] text-[12px] md:text-[18px]'; // following state regular
    } else {
      baseSize = 'w-[80px] h-[31px] md:w-[138px] md:h-[53px] text-[12px] md:text-[18px]'; // default state regular
    }

    const colorStyles = isFavorited ? 'bg-beige-light border-none' : 'bg-primary-yellow border-none';

    return `cursor-pointer rounded-full disabled:cursor-not-allowed ${baseSize} ${colorStyles}`;
  }, [isInsideTheModal, isFavorited]);

  if (!isLoggedIn) {
    return null;
  }

  return (
    <div className={`${isInsideTheModal ? 'md:w-[144px]' : 'md:w-[160px]'} flex justify-center`}>
      <button type="button" disabled={loading} onClick={onFollow} className={buttonStyles}>
        <div className="flex items-center justify-center font-medium">
          <FontAwesomeIcon
            className={`ml-0.5 ${isInsideTheModal ? 'text-[12px] md:text-[18px]' : 'text-[14px] md:text-[20px]'}`}
            icon={favIcon}
            color="black"
            spin={favIcon === faSpinner}
          />
          <span className="mx-2 text-black">{isFavorited ? 'Following' : 'Follow'}</span>
        </div>
      </button>
    </div>
  );
};
export default FollowButton;
