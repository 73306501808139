import { isNumber } from 'lodash-es';
import { useCallback, useEffect, useMemo, useRef, useState } from 'preact/hooks';
import useFetch from 'use-http';
import { useAnalytics } from 'zl-shared/dist/video-analytics';

import LoaderSpinner from '@/components/LoaderSpinner';
import VideoControls from '@/components/VideoControls';
import { videoImperativeHandle } from '@/components/VideoControls/helper';
import getDeviceId from '@/helpers/getDeviceId';
import { useSelector } from '@/redux/helper';
import { buildURL } from '@/shared/fetch';
import { trackMP } from '@/shared/mp';

import { useIsHabitatTabbed } from '../../hooks';
import HabitatVideoOverlay from './HabitatVideoOverlay';
import style from './style.scss';

const SHOW_OVERLAY_TIME_THRESHOLD = 6;

const sendAnalytics = (
  /** @type {import('zl-shared/dist/video-analytics').ReportMetrics<Record<string, any>>} */ metrics,
) => {
  // only log the events that is increased in `durationPlayingDelta`
  if (metrics.durationPlayingDelta <= 0) return;
  const { videoURL, ...rest } = metrics.userData;
  trackMP('video-analytics', {
    ...rest,
    action: metrics.action,
    viewId: metrics.viewId,
    viewerId: metrics.viewerId,
    videoId: metrics.videoId,
    videoTimestamp: metrics.timestamp,
    videoURL,
    duration: metrics.duration,
    durationPlaying: metrics.durationPlaying,
    videoDuration: metrics.videoDuration,
    durationPlayingDelta: metrics.durationPlayingDelta,
    durationBuffering: metrics.durationBuffering,
    position: metrics.position,
  });
};

/**
 * Generates a video player component with various functionalities like playing, pausing, seeking, and displaying controls.
 *
 * @param {object} props
 * @param {string} props.videoURL - The URL of the video to be played
 * @param {string} props.videoId - The unique identifier of the video
 * @param {import('preact/compat').CSSProperties['width']} [props.width] - The width of the video player
 * @param {import('preact/compat').CSSProperties['height']} [props.height] - The height of the video player
 * @param {function} [props.onEnd] - Callback function to be executed when the video ends
 * @param {function} [props.onLoad] - Callback function to be executed when the video is loaded
 * @param {string} [props.className] - CSS class name for styling
 * @param {boolean} [props.autoPlay] - Flag to automatically start playing the video
 * @param {boolean} [props.muted] - Flag to mute the video
 * @param {boolean} [props.logView] - Flag to log the view of the video
 * @param {boolean} [props.controls] - Flag to display video controls
 * @param {boolean} [props.loop] - Flag to loop the video playback
 * @param {boolean} [props.isGuest] - Flag indicating if the viewer is a guest
 * @param {function} [props.onNextHandler] - Callback function to handle next video
 * @param {function} [props.onMuteChange] - Callback function to handle video mute change
 * @param {number} [props.currentTime] - The current time of the video playback
 * @param {string} [props.alt] - Alternative text for the video
 * @param {boolean} [props.stopAnalytics] - Flag to stop reporting analytics data
 * @param {Record<string, string | number | null | undefined>} [props.analyticsData] - Analytics data for passing into video analytics
 * @return {import('preact').JSX.Element} The video player component
 */
const VideoPlayer = ({
  videoURL,
  videoId,
  width,
  height,
  onEnd,
  onLoad,
  className,
  autoPlay = false,
  muted = false,
  logView = true,
  controls = true,
  loop = false,
  isGuest,
  onNextHandler,
  onMuteChange,
  currentTime,
  alt,
  analyticsData,
  stopAnalytics = false,
}) => {
  const videoRef = useRef();
  const timeoutRef = useRef();
  const containerRef = useRef();
  const controllerRef = useRef();
  const [hasUserPlayed, setHasUserPlayed] = useState(false);
  const [isPlaying, setIsPlaying] = useState(autoPlay);
  const [isLoading, setIsLoading] = useState(true);
  const [isSeeking, setIsSeeking] = useState(false);
  const [videoLength, setVideoLength] = useState(0);
  const [timeElapsed, setTimeElapsed] = useState(0);
  const [showControls, setShowControls] = useState(false);
  const [forceRerender, setForceRerender] = useState(false);
  const userId = useSelector((state) => state.user.userId);
  const isMobile = useIsHabitatTabbed();
  const [showOverlay, setShowOverlay] = useState(false);
  useEffect(() => {
    if (!isMobile) {
      setShowControls(true);
    }
  }, [isMobile]);

  const handleScreenClick = useCallback(() => {
    if (isMobile) {
      const action = showControls ? 'show' : 'hide';
      setShowControls(!showControls);
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }

      if (action === 'show') {
        timeoutRef.current = setTimeout(() => {
          setShowControls(false);
        }, 5000);
      }
    }
  }, [isMobile, showControls]);

  const { put: setVideoAsViewed } = useFetch(buildURL(`/videos/${videoId}/${userId ?? 'guest'}/viewed`), {
    credentials: 'include',
    cachePolicy: 'no-cache',
  });

  const onPlay = useCallback(() => {
    handleScreenClick();
    if (!hasUserPlayed && logView) {
      setHasUserPlayed(true);
      if (!isGuest) {
        setVideoAsViewed();
      }
    }
    setIsLoading(false);
    setIsPlaying(true);
  }, [handleScreenClick, hasUserPlayed, isGuest, logView, setVideoAsViewed]);

  const onPause = () => {
    handleScreenClick();
    if (isPlaying) {
      videoRef.current.pause();
      setIsPlaying(false);
    } else {
      const playPromise = videoRef.current.play();
      setIsSeeking(true);

      if (playPromise !== undefined) {
        playPromise
          .then((e) => {
            setIsPlaying(true);
            setIsSeeking(false);
          })
          .catch((error) => {
            console.error(error);
          });
      }
    }
  };

  const onTimeUpdate = useCallback(() => {
    const currentTime = Math.round(videoRef.current.currentTime);
    setTimeElapsed(currentTime);

    const { duration } = videoRef.current;
    const timeRemaining = duration - currentTime;

    // show overlay in last SHOW_OVERLAY_TIME_THRESHOLD
    setShowOverlay(timeRemaining <= SHOW_OVERLAY_TIME_THRESHOLD);
  }, []);

  const onSeeking = useCallback(() => {
    setIsSeeking(true);
  }, []);

  const onSeeked = useCallback(() => {
    setIsSeeking(false);
  }, []);

  const onEndHandler = useCallback(() => {
    setIsPlaying(false);
    if (typeof onEnd === 'function') {
      onEnd();
    }
  }, [onEnd]);

  const onLoadMetadata = useCallback(() => {
    setVideoLength(Math.round(videoRef.current.duration));
    if (autoPlay) {
      videoRef.current.play().catch(console.error);
    }
  }, [autoPlay]);

  const onLoadedData = useCallback(() => {
    if (typeof onLoad === 'function') {
      onLoad();
    }

    setForceRerender(true);
    setIsLoading(false);
  }, [onLoad]);

  const updateVideoTimeHandler = useCallback((value) => {
    videoRef.current.currentTime = value;
  }, []);

  useEffect(() => {
    if (isNumber(currentTime)) {
      updateVideoTimeHandler(currentTime);
    }
  }, [currentTime, updateVideoTimeHandler]);

  useEffect(
    () => () => {
      setIsLoading(true);
      setIsPlaying(false);
      setHasUserPlayed(false);
      setVideoLength(0);
      setTimeElapsed(0);
    },
    [videoURL],
  );

  useEffect(() => {
    const video = videoRef?.current;
    if (!video) {
      return undefined;
    }

    video.addEventListener('ended', onEndHandler);
    video.addEventListener('play', onPlay);
    video.addEventListener('timeupdate', onTimeUpdate);
    video.addEventListener('loadedmetadata', onLoadMetadata);
    video.addEventListener('loadeddata', onLoadedData);
    video.addEventListener('seeked', onSeeked);
    video.addEventListener('seeking', onSeeking);

    return () => {
      video.removeEventListener('ended', onEndHandler);
      video.removeEventListener('play', onPlay);
      video.removeEventListener('timeupdate', onTimeUpdate);
      video.removeEventListener('loadedmetadata', onLoadMetadata);
      video.removeEventListener('loadeddata', onLoadedData);
      video.removeEventListener('seeked', onSeeked);
      video.removeEventListener('seeking', onSeeking);
    };
  }, [
    videoRef,
    videoURL,
    onEndHandler,
    onPlay,
    onTimeUpdate,
    onLoadMetadata,
    onLoadedData,
    // onSuspendeHandler,
  ]);

  videoImperativeHandle(controllerRef, containerRef.current, videoRef.current);

  useAnalytics(stopAnalytics ? false : videoRef, userId || getDeviceId(), {
    videoId,
    send: sendAnalytics,
    userData: useMemo(
      () => ({ ...analyticsData, videoURL }),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [videoURL, ...(analyticsData ? Array.from(Object.values(analyticsData)) : [])],
    ),
  });

  return (
    <div
      ref={containerRef}
      onMouseEnter={() => setShowControls(true)}
      onMouseLeave={() => setShowControls(false)}
      onClick={handleScreenClick}
      style={{
        width,
        height,
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        maxHeight: '100%',
      }}
    >
      {isLoading && <div className="preflight skeleton absolute inset-0 bg-grey-5" />}
      {isSeeking && !isLoading && <LoaderSpinner absolute className={style.seekLoader} />}

      {showOverlay && (
        <HabitatVideoOverlay
          habitatId={analyticsData?.habitatId}
          mediaId={videoId}
          videoType={analyticsData?.videoType}
          isInsideTheModal={analyticsData?.player === 'ugc-modal'}
        />
      )}

      {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
      <video
        src={`${videoURL}#t=0.1`}
        playsInline
        style={{ width, height }}
        ref={videoRef}
        muted={muted}
        className={className}
        loop={loop}
        title={alt}
        autoPlay={autoPlay}
        onContextMenu={(e) => e.preventDefault()}
      />
      {controls && (
        <VideoControls
          ref={controllerRef}
          showControls={showControls}
          showPlayControl
          showVolumeControl
          showFullscreenControl
          showSeekBar
          showTimeStats
          mode="vod"
          muted={muted}
          isPlaying={isPlaying}
          key={`${videoURL}-control`}
          onPauseHandler={onPause}
          videoLength={videoLength}
          timeElapsed={timeElapsed}
          updateVideoTimeHandler={updateVideoTimeHandler}
          onNextHandler={onNextHandler}
          onMuteChange={onMuteChange}
        />
      )}
      {!forceRerender && <div style={{ visibility: 'hidden', pointerEvents: 'none' }} />}
    </div>
  );
};

export default VideoPlayer;
