import { useImperativeHandle } from 'preact/hooks';

export const exitFullMode = () => {
  if (document.exitFullscreen) {
    document.exitFullscreen();
  } else if (document.webkitExitFullscreen) {
    /* Safari */
    document.webkitExitFullscreen();
  } else if (document.msExitFullscreen) {
    /* IE11 */
    document.msExitFullscreen();
  }
};

export const videoImperativeHandle = (controllerRef, containerRef, videoRef) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useImperativeHandle(controllerRef, () => ({
    requestFullscreen(mode) {
      const target = containerRef;

      if (target.requestFullscreen) {
        target.requestFullscreen();
      } else if (target.webkitRequestFullscreen) {
        /* Safari */
        target.webkitRequestFullscreen();
      } else if (target.msRequestFullscreen) {
        /* IE11 */
        target.msRequestFullscreen();
      } else if (target.webkitSupportsFullscreen) {
        /* Mobile Safari on video target */
        target.webkitEnterFullScreen();
      } else {
        // Fallback for Safari with no support for full screen for none video elements
        const video = target.querySelector('video');
        if (video?.webkitSupportsFullscreen) {
          video.webkitEnterFullScreen();
        }
      }
    },
    exitFullscreen() {
      exitFullMode();
    },
    get muted() {
      return videoRef.muted;
    },
    set muted(muted) {
      // eslint-disable-next-line no-param-reassign
      videoRef.muted = muted;
    },
    set volume(volume) {
      // eslint-disable-next-line no-param-reassign
      videoRef.volume = volume;
    },
    requestPictureInPicture() {
      videoRef.requestPictureInPicture();
    },
  }));
};
