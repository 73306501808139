/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import MyAlbumState from './types';

const initialState: MyAlbumState = {
  availableZoos: [],
  availableZooIds: [],
  availableAnimals: [],
  availableAnimalIds: [],
  habitatSlug: null,
  zooSlug: null,
  queryURL: null,
  type: 'clips',
  album: {
    total: null,
    list: [],
    page: 1,
  },
  filters: {
    showAnimalFilter: true,
    habitatIds: [],
    showZooFilter: true,
    zooIds: [],
  },
  shareModal: {
    open: false,
    mediaId: null,
    nextId: null,
    prevId: null,
    data: {},
  },
};

const myAlbumSlice = createSlice({
  name: 'myAlbum',
  initialState,
  reducers: {
    setFilterOptions: (
      state,
      {
        payload,
      }: PayloadAction<{
        animals: string[] | null;
        habitatIds: string[] | null;
        zoos: string[] | null;
        zooIds: string[] | null;
      }>,
    ) => {
      state.availableAnimals = payload.animals;
      state.availableAnimalIds = payload.habitatIds;
      state.availableZoos = payload.zoos;
      state.availableZooIds = payload.zooIds;
    },
    setQueryURL: (state, { payload }: PayloadAction<string>) => {
      state.queryURL = payload;
    },
    toggleAnimalFilter: (state) => {
      state.filters.showAnimalFilter = !state.filters.showAnimalFilter;
    },
    toggleZooFilter: (state) => {
      state.filters.showZooFilter = !state.filters.showZooFilter;
    },
    setAnimalFilter: (state, { payload }: PayloadAction<string[]>) => {
      state.filters.habitatIds = payload;
    },
    setZooFilter: (state, { payload }: PayloadAction<string[]>) => {
      state.filters.zooIds = payload;
    },
    setAlbumData: (state, { payload }: PayloadAction<MyAlbumState['album']>) => {
      state.album.list = payload.list;
      state.album.total = payload.total;
    },
    setTypeData: (state, { payload }: PayloadAction<{ type: 'clips' | 'photos' }>) => {
      state.type = payload.type;
      state.album.page = 1;
    },
    setPageData: (state, { payload }: PayloadAction<{ page: number }>) => {
      state.album.page = payload.page;
    },
    appendAlbumData: (state, { payload }: PayloadAction<MyAlbumState['album']>) => {
      state.album.page += 1;
      state.album.list = [...state.album.list, ...payload.list];
    },
    changeContentVisibility: (state, { payload }: PayloadAction<{ mediaId: string; action: 'hide' | 'unhide' }>) => {
      state.album.list = (state.album?.list || []).map((media) =>
        media._id === payload.mediaId ? { ...media, disabled: payload.action === 'hide' } : media,
      );
    },
    updateAlbumData: (
      state,
      { payload }: PayloadAction<{ id: string; data: Partial<MyAlbumState['album']['list'][0]> }>,
    ) => {
      state.album.list = state.album.list.map((media) => (media._id === payload.id ? { ...media, ...payload.data } : media));
      if (state.shareModal.open && state.shareModal.mediaId === payload.id) {
        state.shareModal.data = payload.data;
      }
    },
    removeMediaFromMyAlbum: (state, { payload }: PayloadAction<{ id: string }>) => {
      state.album.list = state.album.list.filter((media) => media._id !== payload.id);
    },
    addTagToContent: (state, { payload }: PayloadAction<{ contentId: string; tag: string; userId: string }>) => {
      const { contentId, tag, userId } = payload;
      state.album.list = state.album.list.map((content) => {
        if (content._id === contentId) {
          const allExistingTags = content.tags || [];
          const existingTag = allExistingTags.find((t) => t.tag === tag);

          if (existingTag) {
            return {
              ...content,
              tags: allExistingTags.map((t) => (t.tag === tag ? { ...t, users: [...t.users, userId] } : t)),
            };
          }
          return {
            ...content,
            tags: [...allExistingTags, { tag, users: [userId] }],
          };
        }
        return content;
      });
    },
    removeTagFromContent: (state, { payload }: PayloadAction<{ contentId: string; tag: string; userId: string }>) => {
      const { contentId, tag, userId } = payload;
      state.album.list = state.album.list.map((content) =>
        content._id === contentId
          ? {
              ...content,
              tags: content.tags.map((t) => (t.tag === tag ? { ...t, users: t.users.filter((id) => id !== userId) } : t)),
            }
          : content,
      );
    },
    setShareModalMediaId: (state, { payload }: PayloadAction<{ mediaId: string; videoData?: Object }>) => {
      if (payload.videoData) {
        // to resolve the issue of next episode button working on user's album page
        state.shareModal = {
          open: true,
          mediaId: payload.mediaId,
          data: payload.videoData,
          nextId: null,
          prevId: null,
        };
      } else {
        const { list } = state.album;
        const index = list.findIndex((item) => item._id === payload.mediaId);
        if (index >= 0) {
          state.shareModal = {
            open: true,
            mediaId: payload.mediaId,
            data: list[index],
            nextId: index + 1 < list.length ? list[index + 1]._id : null,
            prevId: index - 1 >= 0 ? list[index - 1]._id : null,
          };
        }
      }
    },
    setShareModalData: (state, { payload }: PayloadAction<Omit<MyAlbumState['shareModal'], 'open'>>) => {
      state.shareModal = {
        open: true,
        mediaId: payload.mediaId,
        nextId: payload.nextId,
        prevId: payload.prevId,
        data: payload.data,
      };
    },
    closeShareModal: (state) => {
      state.shareModal = {
        open: false,
        mediaId: null,
        nextId: null,
        prevId: null,
        data: {},
      };
    },
    setHabitat: (state, { payload }: PayloadAction<{ habitat: { habitatSlug: string; zooSlug: string } }>) => {
      const { habitatSlug, zooSlug } = payload.habitat;
      state.habitatSlug = habitatSlug;
      state.zooSlug = zooSlug;
    },
  },
});

export default myAlbumSlice;
